import React from 'react'
import { CASUAL, CRICKET_LEARN, GAMES } from '../../components/internal-links'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Blogs from '../../components/Blogs/Blogs'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import { generateBreadcrumbsSchema } from '../../lib/generate-schema'
import { CRICKET_LEARN as CRICKET_DOWNLOAD_LINK } from '../../components/download-links'
import CRICKET_LEARN_PAGES from '../../cricket-tags'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Games',
    url: GAMES,
  },
  {
    title: 'Casual',
    url: CASUAL,
  },
  {
    title: 'Learn Cricket',
    url: CRICKET_LEARN,
  },
]

const LearnCricket: React.FC = () => {
  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      apkLink={CRICKET_DOWNLOAD_LINK}
    >
      <SEO
        title="Cricket: Learn everything about cricket from scratch | Mega"
        description="Get to know the best websites, gameplay, playing strategies, and how to earn money via online playing cricket / IPL games. Read the articles listed exclusively for you."
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
      />
      <Blogs
        blogType="cricket"
        tags={CRICKET_LEARN_PAGES}
        prefix={
          `${CASUAL}${process.env.GATSBY_CMS_LEARN_CRICKET_PREFIX}` || '/'
        }
        title={
          <>
            Learn Cricket - <span className="underline">Guide</span>
          </>
        }
      />
    </Layout>
  )
}

export default LearnCricket
